import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="content row blue">
    <hr />
    <p>
      <a
        href="https://cdn.centerforinquiry.org/wp-content/uploads/2022/04/20144618/Progress-Report-2021-WEBV-v2.pdf"
        target="_blank"
      >
        Download a PDF of our Annual Report
      </a>
    </p>
    <p>Help us make a difference in 2022.</p>
    <a href="https://www.centerforinquiry.net/support_cfi/" target="_blank">
      <button className="btn white-button-on-blue">Support CFI</button>
    </a>
    <p>
      <a href="https://www.centerforinquiry.org/" target="_blank">
        Center for Inquiry
      </a>{" "}
      © 2022
    </p>
  </footer>
)

export default Footer
