import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { FaArrowUp } from "react-icons/fa"

const Header = () => (
  <>
    <div id="back-to-top" className="big-shadow hidden show-block">
      <a href="#home-top">
        TOP <FaArrowUp />
      </a>
    </div>
    <header className="main-nav sticky-top dark-blue big-shadow hidden">
      <nav className="navbar navbar-expand-lg navbar-dark">
        <a className="home-button" target="_blank">
          <img
            className="d-block d-lg-none"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/CFImark2017-on-blue.svg"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-expanded="true"
          aria-controls="navbarNav"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item mx-2">
              <a href="#Reawakening-title" className="fighting-link nav-link">
                Reawakening
              </a>
            </li>
            <li className="nav-item mx-2">
              <a href="#Fortifying-title" className="fighting-link nav-link">
                Fortifying
              </a>
            </li>
            <li className="nav-item mx-3">
              <a href="#home-top" className="home-button nav-link">
                <img
                  className="d-none d-lg-block"
                  src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/CFImark2017-on-blue.svg"
                />
              </a>
            </li>
            <li className="nav-item mx-2">
              <a href="#Defending-title" className="fighting-link nav-link">
                Defending
              </a>
            </li>
            <li className="nav-item mx-2">
              <a href="#Sharing-title" className="fighting-link nav-link">
                Sharing
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </>
)

export default Header
